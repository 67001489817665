var Otto = Otto || {};

/**
 * Provide background slide functionality.  Module is based on a
 * 3 image size system, with breakpoints provided for each
 * window size specification.
 *
 * DEPENDENCIES: This module requires jquery and vegas to support
 * image background display/slideshow functionality.
 */

Otto.backgrounds = (function(my, $) {
    var exports = {},
        $target,            // jQuery target element
        pathsSm = [],       // array of images for small screens
        pathsMd = [],       // array of images for medium screens
        pathsLg = [],       // array of images for large screens
        currSize = null,
        duration = null,
        sizeBreakPts = null,
        initd = false,      // is slideshow initialized?

        SMALL = 'sm',       // Set some 'constants' for size reference
        MEDIUM = 'md',
        LARGE = 'lg';

    // Process data provided within markup.  $data represents jQuery
    // object tied to dom UL element storing image data.
    function processData($data) {
        duration = $data.data('duration') ? $data.data('duration') : 5;
        sizeBreakPts = {};

        // Fill in size breakpoint data
        var sizes,
            index = 0,
            sizeKeys = [SMALL,MEDIUM,LARGE];
        if ($data.data('sizes')) {
            sizes = $data.data('sizes').split(',');
            for (index = 0; index < sizes.length; index++) {
                sizes[index] = parseInt(sizes[index], 10);
            }
        } else {
            sizes = [768,1000,1350];
        }

        for (index = 0; index < sizes.length; index++) {
            sizeBreakPts[sizeKeys[index]] = parseInt(sizes[index], 10);
        }

        // Process image paths
        var pathItems = $data.find('li');
        $.each(pathItems, function(index, el) {
            var $item = $(el);
            pathsSm.push({src: $item.data('img-sm')});
            pathsMd.push({src: $item.data('img-md')});
            pathsLg.push({src: $item.data('img-lg')});
        });

    }

    function getSize() {
        var elSize = $target.width();

        if (elSize >= sizeBreakPts[LARGE]) {
            return LARGE;
        } else if (elSize >= sizeBreakPts[MEDIUM]) {
            return MEDIUM;
        } else {
            return SMALL;
        }

    }

    function setContainerSize() {
        $target.height($(window).height());
    }


    function checkForSizeChange() {
        // Always ensure container is properly sized
        setContainerSize();

        // If window width setting is the same, do nothing.  We
        // support 3 different sizes (small, medium, and large).
        // If we move from one size to the other, we need to
        // adjust the slideshow.
        var winSize = getSize();
        if (winSize === currSize) return;

        // Otherwise, load image in corresponding size
        currSize = winSize;
        setupSlideshow();
    }

    // By specifying window size, we return set of images
    // optimized for this window
    function getImgArray(size) {
        var srcArr = null;
        switch (size) {
            case SMALL:
                return pathsSm.slice(0);
            case MEDIUM:
                return pathsMd.slice(0);
            case LARGE:
                return pathsLg.slice(0);
        }
    }

    function setupSlideshow() {
        var startIndex = 0,
            imgArr = null;

        if (initd) {
            // Need to kill slideshow first...
            startIndex = $target.vegas('current');
            $target.vegas('destroy');
        }

        imgArr = getImgArray(currSize);

        $target.vegas({
            slides: imgArr,
            slide: startIndex,
            timer: false,
            transition: 'fade',
            animation: 'none',
            //animation: 'random',    // random ken burns
            //animationDuration:1000,
            delay: duration * 1000
        });

        initd = true;
    }

    function wireEvents() {
        $(window).resize(function(evt){
            checkForSizeChange();
        });
    }

    // Note: Parameters specified here are to be sent as strings.
    // Provide a data source UL element, and background target
    // element (this is where slides will be applied)
    exports.init = function(dataSrc, bgTarget) {
        var $data = $(dataSrc);     // jQuery UL element storing our data
        $target = $(bgTarget);

        // Check to see if there is an actual slide set to process,
        // and target element to fill.
        if (($data.length === 0) || ($target.length === 0)) return;

        // Process data found in our markup
        processData($data);

        // Wire up our events
        wireEvents();

        // Get current size, based on window size
        currSize = getSize();

        // Ensure our container size is set properly
        setContainerSize();

        // And start up the slideshow...
        setupSlideshow();

    };

    return exports;
})(Otto, jQuery);


