var Otto = Otto || {};

/**
 * Provide map functionality.  Gives us a central location to
 * manage map styling to ensure consistency across pages.
 *
 * DEPENDENCIES: This module requires jquery and the Google
 * maps API.
 */

/* global jQuery, google */

Otto.map = (function(my, $) {
    var exports = {},
        map = null,
        center = null,
        markers = [],
        infoWindow = null;      // Keep local reference - allows us to close open
                                // window when new one is called.;

    function initMap(id, latLng) {

        var map = new google.maps.Map(document.getElementById(id), {
            zoom: 15,
            center: latLng,
            styles: [{'featureType':'landscape','stylers':[{'saturation':-100},{'lightness':65},{'visibility':'on'}]},{'featureType':'poi','stylers':[{'saturation':-100},{'lightness':51},{'visibility':'simplified'}]},{'featureType':'road.highway','stylers':[{'saturation':-100},{'visibility':'simplified'}]},{'featureType':'road.arterial','stylers':[{'saturation':-100},{'lightness':30},{'visibility':'on'}]},{'featureType':'road.local','stylers':[{'saturation':-100},{'lightness':40},{'visibility':'on'}]},{'featureType':'transit','stylers':[{'saturation':-100},{'visibility':'simplified'}]},{'featureType':'administrative.province','stylers':[{'visibility':'off'}]},{'featureType':'water','elementType':'labels','stylers':[{'visibility':'on'},{'lightness':-25},{'saturation':-100}]},{'featureType':'water','elementType':'geometry','stylers':[{'hue':'#ffff00'},{'lightness':-25},{'saturation':-97}]}]
        });


        // To recenter map on page resize...
        function calculateCenter() {
            center = map.getCenter();
        }
        google.maps.event.addDomListener(map, 'idle', function() {
            // NOTE: 'idle' is fired at the end of any pan/zoom actions
            calculateCenter();
        });
        google.maps.event.addDomListener(window, 'resize', function() {
            map.setCenter(center);
        });

        return map;
    }

    exports.getMap = function(id, latLng) {
        center = latLng;
        if (map === null) {
            map = initMap(id, center);
        } else if (latLng !== undefined) {
            // Center map on new centerpoint
            map.setCenter(center);
            map.setZoom(15);
        }
        return map;
    };

    exports.getPoint = function(lat, lng) {
        return new google.maps.LatLng(lat, lng);
    };

    exports.fitMapToMarkers = function() {
        if (markers.length === 0) return;

        // Set up a one-time listener to ensure the zoom doesn't drop
        // below 15 when resetting the bounds (could potentionally happen
        // if only 1 marker is present, or if markers are very close)
        google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
            if (this.getZoom() > 15) {
                this.setZoom(15);
            }
        });

        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].getPosition());
        }

        map.fitBounds(bounds);
    };

    exports.clearMarkers = function() {
        if (map === null) return;
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
        markers = [];
    };

    exports.addMarker = function(latLng, title, infoWinText, image) {
                                
        if (map === null) return;
        image = (image === undefined) ? '/assets/img/location.png' : image;
        title = ((title === undefined) || (title === null)) ? '' : title;
        var marker = new google.maps.Marker({
            position: latLng,
            map: map,
            title: title,
            icon: image
        });

        // Check to see if an info window should be created
        if (infoWinText !== null && infoWinText !== '') {
            // Add info window for marker click...
            google.maps.event.addListener(marker, 'click', function() {
                if(infoWindow) {
                    infoWindow.close();
                } 
                infoWindow = new google.maps.InfoWindow();
                infoWindow.setContent(infoWinText);
                infoWindow.open(map, marker);
            });
        }

        // Store marker in our array -- will allow us to remove all existing
        // markers later on if needed.
        markers.push(marker);
        return marker;
    };

    return exports;
})(Otto, jQuery);